import Footer from "../components/Footer";
import RecoverPassForm from "../components/RecoverPassForm";

function NewPlace(){
    return (
        <div>
        <RecoverPassForm />
        <Footer />
        </div>
    );
}
  
export default NewPlace;
  
